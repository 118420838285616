import React, { useState, useCallback, useRef, useEffect } from 'react'
import MuiTextField from '@material-ui/core/TextField'
import Box from '@material-ui/core/Box'
import useT from '../../../i18ns/admin/useT'
import { useInternValue } from '../../../utils/default/FormHook'
import useGlobalDialog from '../../../providers/admin/dialog/useGlobalDialog'
import { createGlobalDialogConfig } from '../../../utils/default/DialogHelper'
import PlayerInputButton from './PlayerInputButton'
import useGDK from '../../../providers/admin/gdk/useGDK'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import TKeyType from '../../../i18ns/admin/TKeyType'
import { AdminPlayerInfo, PermissionType, PlayerInfoMemoType } from '@golden/gdk-admin'
import { makeStyles } from '@material-ui/core'
import { useChecker } from '../../../utils/admin/AdminRouteHook'

const useStyles = makeStyles((theme) => ({
  textarea: {
    marginTop: theme.spacing(1)
  }
}))

interface PropTypes {
  id: number
  res: AdminPlayerInfo
  type: PlayerInfoMemoType
  disabled?: boolean
  reload: () => void
}

const typeMapLabel: { [key in PlayerInfoMemoType]: TKeyType } = {
  rc: 'common.riskMemo',
  cs: 'common.battleFieldMemo',
  fm: 'common.financeMemo'
}
const typeMapPermission: { [key in PlayerInfoMemoType]: PermissionType } = {
  rc: PermissionType.PLAYER_MANAGEMENT_INFO_RC_MEMO,
  cs: PermissionType.PLAYER_MANAGEMENT_INFO_CS_MEMO,
  fm: PermissionType.PLAYER_MANAGEMENT_INFO_FM_MEMO
}
const typeMapMemo = {
  rc: 'risk_memo',
  cs: 'battle_field_memo',
  fm: 'finance_memo'
} as const

const TextField = React.memo(MuiTextField)

const PlayerMemoInput: React.FC<PropTypes> = (props) => {
  const { id, res, type, reload, disabled: buttonDisabled = false } = props
  const gdk = useGDK()
  const globalDialog = useGlobalDialog()
  const writable = useChecker([typeMapPermission[type]])
  const commonClasses = useCommonStyles()
  const classes = useStyles()
  const [value, setValue] = useInternValue<string>(res[typeMapMemo[type]], res[typeMapMemo[type]])
  const [disabled, setDisabled] = useState<boolean>(true)
  const { t } = useT()
  const ref = useRef<HTMLInputElement | null>(null)

  const handleUpdate = useCallback(() => setDisabled(false), [])
  const handleSave = useCallback(() => {
    gdk.player.updateInfoMemo(id, { type, memo: value }).subscribe({
      next: () => {
        setDisabled(true)
        reload()
      },
      error: (error) => {
        globalDialog.setConfig(createGlobalDialogConfig({
          showIcon: true,
          showCancel: false,
          variant: 'error',
          message: error.message
        }))
        globalDialog.setOpen({
          id: 'setNicknameFail',
          value: true,
          isOK: false
        })
      }
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gdk, id, value])
  useEffect(() => {
    if (!disabled) ref.current?.focus()
  }, [disabled])
  return (<>
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <Box height={32} display="flex" alignItems="center">{t(typeMapLabel[type])}</Box>
      { writable && (<PlayerInputButton
        title={disabled ? t('common.update') : t('common.save')}
        onClick={disabled ? handleUpdate : handleSave}
        disabled={buttonDisabled}
        isAdornment={false}
      />) }
    </Box>
    <TextField
      value={value}
      multiline
      fullWidth
      type="text"
      margin="normal"
      variant="outlined"
      rows={10}
      className={classes.textarea}
      InputProps={{
        classes: { input: commonClasses.memoField },
        inputProps: {
          maxLength: 500
        },
        inputRef: ref
      }}
      onChange={useCallback((event) => {
        setValue(event.target.value)
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [])}
      FormHelperTextProps={{ error: !disabled }}
      disabled={disabled}
    />
  </>)
}

export default React.memo(PlayerMemoInput)
