import { DebitCardValidationProviderType } from '@golden/gdk-admin'
import TKeyType from '../../i18ns/admin/TKeyType'

const debitcardValidationProviderName: { [key in DebitCardValidationProviderType]: TKeyType } = {
  [DebitCardValidationProviderType.SHUMAIDATA]: 'debitCardValidationProvider.shumaidata',
  [DebitCardValidationProviderType.FEGINE]: 'debitCardValidationProvider.fegine',
  [DebitCardValidationProviderType.DEEPFINCH]: 'debitCardValidationProvider.deepfinch'
}

export default debitcardValidationProviderName
