import React from 'react'
import { searchToRequest } from './ActivityRecordDetailForm'
import { useRequestFromSearch } from '../../../utils/default/ComplexFlowHook'
import ActivityRecordNewMemberBonusTable from './ActivityRecordNewMemberBonusTable'
import ActivityRecordRecommendFriendTable from './ActivityRecordRecommendFriendTable'
import ActivityRecordRankBonusTable from './ActivityRecordRankBonusTable'
import ActivityRecordDailySignInTable from './ActivityRecordDailySignInTable'
import { ActivityType } from '@golden/gdk-admin'
import ActivityRecordNewYear2023Table from './ActivityRecordNewYear2023Table'
import ActivityRecordNewYear2024Table from './ActivityRecordNewYear2024Table'
import ActivityRecordDragonBoatFestival2023Table from './ActivityRecordDragonBoatFestival2023Table'
import ActivityRecordDepositBonusTable from './ActivityRecordDepositBonusTable'
import ActivityRecordUefaBuildingUpTable from './ActivityRecordUefaBuildingUpTable'
import ActivityRecordUefaStarsTable from './ActivityRecordUefaStarsTable'
import ActivityRecordWeeklyBonusDrawTable from './ActivityRecordWeeklyBonusDrawTable'

const tableMap: { [key in ActivityType as string]: React.ReactElement } = {
  [ActivityType.NEW_MEMBER_BONUS]: (<ActivityRecordNewMemberBonusTable />),
  [ActivityType.RECOMMEND_FRIEND]: (<ActivityRecordRecommendFriendTable />),
  [ActivityType.UPGRADE_BONUS_GOLD]: (<ActivityRecordRankBonusTable activity={ActivityType.UPGRADE_BONUS_GOLD}/>),
  [ActivityType.DAILY_SIGN_IN_BONUS]: (<ActivityRecordDailySignInTable />),
  [ActivityType.WEEKLY_BONUS_GOLD]: (<ActivityRecordRankBonusTable activity={ActivityType.WEEKLY_BONUS_GOLD} />),
  [ActivityType.BIRTHDAY_BONUS_GOLD]: (<ActivityRecordRankBonusTable activity={ActivityType.BIRTHDAY_BONUS_GOLD} />),
  [ActivityType.NEW_YEAR_2023]: (<ActivityRecordNewYear2023Table />),
  [ActivityType.DRAGON_BOAT_FESTIVAL_2023]: (<ActivityRecordDragonBoatFestival2023Table />),
  [ActivityType.NEW_YEAR_2024]: (<ActivityRecordNewYear2024Table />),
  [ActivityType.DEPOSIT_BONUS]: (<ActivityRecordDepositBonusTable />),
  [ActivityType.UEFA_BUILDING_UP]: (<ActivityRecordUefaBuildingUpTable />),
  [ActivityType.UEFA_STARS]: (<ActivityRecordUefaStarsTable />),
  [ActivityType.WEEKLY_BONUS_DRAW]: (<ActivityRecordWeeklyBonusDrawTable />)
}
const ActivityRecordActivityTable: React.FC = () => {
  const request = useRequestFromSearch({ searchToRequest })
  if (request === undefined) return null
  return (
    <React.Fragment>
      {tableMap[request.activity] || null}
    </React.Fragment>
  )
}

export default React.memo(ActivityRecordActivityTable)
