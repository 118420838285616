import React, { useMemo, useContext } from 'react'
import { ActivityDurationType, ActivityFrequencyType, ActivityDepositConditionType, ActivityBetConditionType, GameType } from '@golden/gdk-admin'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import DateTimePicker, { PropTypes as TimePickerProps } from '../../default/form/DateTimePicker'
import { ValueGetter } from '../../../utils/default/FormHook'
import { InitialFormFunc } from '../../../utils/default/ComplexFlowHook'
import useT from '../../../i18ns/admin/useT'
import DropDown, { PropTypes as DropDownProps } from '../../../components/default/form/DropDown'
import activityFrequencyName from '../../../constants/admin/activityFrequencyName'
import { ActivityManagementEditLayoutFormType, ActivityManagementFormContext } from './ActivityManagementEditLayoutForm'
import { getValueFromChangeEvent, getValueFromCheckboxEvent, getValueFromValue } from '../../../utils/default/FormHelper'
import FormField from '../../../components/default/form/FormField'
import { BetConditionInput, DepositConditionInput } from './ActivityManagementEditLayoutSettingForm'
import { useChecker } from '../../../utils/admin/AdminRouteHook'

export interface SingleSettingFormType {
  singleHasDepositCondition: boolean
  singleDepositDuration: ActivityDurationType | '-'
  singleDepositType: ActivityDepositConditionType
  singleDepositThreshold: string
  singleHasBetCondition: boolean
  singleBetDuration: ActivityDurationType | '-'
  singleBetType: ActivityBetConditionType
  singleBetThreshold: string
  singleBetGameIds: GameType[]
  singleFrequency: ActivityFrequencyType | '-'
  singleStartAt: Date | null
  singleEndAt: Date | null
}

export const initialForm: InitialFormFunc<SingleSettingFormType> = (form) => ({
  singleHasDepositCondition: false,
  singleDepositDuration: '-',
  singleDepositType: ActivityDepositConditionType.SINGLE,
  singleDepositThreshold: '',
  singleHasBetCondition: false,
  singleBetDuration: '-',
  singleBetType: ActivityBetConditionType.SINGLE,
  singleBetThreshold: '',
  singleBetGameIds: [],
  singleFrequency: '-',
  singleStartAt: null,
  singleEndAt: null,
  ...form
})

export const getValueFromEvent: ValueGetter<SingleSettingFormType> = {
  singleHasDepositCondition: getValueFromCheckboxEvent,
  singleDepositDuration: getValueFromChangeEvent,
  singleDepositType: getValueFromChangeEvent,
  singleDepositThreshold: getValueFromChangeEvent,
  singleHasBetCondition: getValueFromCheckboxEvent,
  singleBetDuration: getValueFromChangeEvent,
  singleBetType: getValueFromChangeEvent,
  singleBetThreshold: getValueFromChangeEvent,
  singleBetGameIds: getValueFromValue,
  singleFrequency: getValueFromChangeEvent,
  singleStartAt: getValueFromValue,
  singleEndAt: getValueFromValue
}

const durationMapFrequncy = {
  '-': null,
  [ActivityDurationType.TODAY]: ActivityFrequencyType.EVERYDAY,
  [ActivityDurationType.YESTERDAY]: ActivityFrequencyType.EVERYDAY,
  [ActivityDurationType.THIS_WEEK]: ActivityFrequencyType.EVERY_WEEK,
  [ActivityDurationType.LAST_WEEK]: ActivityFrequencyType.EVERY_WEEK,
  [ActivityDurationType.THIS_MONTH]: ActivityFrequencyType.EVERY_MONTH,
  [ActivityDurationType.LAST_MONTH]: ActivityFrequencyType.EVERY_MONTH
}

const FrequencyInput: React.FC = () => {
  const { t } = useT()
  const { value: form, error } = useContext(ActivityManagementFormContext)

  const frequncyOptions = useMemo(() => {
    const defaultOption = { value: '-', name: t('common.pleaseChoose') }
    if ((form.singleHasDepositCondition || form.singleHasBetCondition) && form.singleDepositDuration !== '-') {
      const singleFrequency = durationMapFrequncy[form.singleDepositDuration]
      return [
        defaultOption,
        { value: ActivityFrequencyType.ONE_TIME, name: t('common.oneTime') },
        { value: singleFrequency, name: t(activityFrequencyName[singleFrequency]) }
      ]
    }
    return [
      defaultOption,
      ...Object.keys(activityFrequencyName)
        .map((singleFrequency) => ({ value: singleFrequency, name: t(activityFrequencyName[singleFrequency as ActivityFrequencyType]) }))
    ]
  }, [form.singleHasDepositCondition, form.singleHasBetCondition, form.singleDepositDuration, t])

  return (
    <FormField<ActivityManagementEditLayoutFormType, DropDownProps>
      fullWidth
      label={t('common.applyFrequency')}
      context={ActivityManagementFormContext}
      component={DropDown}
      name="singleFrequency"
      options={frequncyOptions}
      disabled={form.isUpdate}
      required
      error={!!error.singleFrequency}
      helperText={error.singleFrequency ?? ''}
    />
  )
}

const DateInput: React.FC = React.memo(() => {
  const { t } = useT()
  const writable = useChecker()
  return (
    <Box display="flex" alignItems="center">
      <Box paddingRight={2}>
        {`${t('common.applyTime')}*`}
      </Box>
      <Box flex={1} display="flex" flexWrap="nowrap">
        <Box flex={1}>
          <FormField<ActivityManagementEditLayoutFormType, TimePickerProps>
            component={DateTimePicker}
            context={ActivityManagementFormContext}
            name="singleStartAt"
            placeholder={t('placeholder.inputBeginAt')}
            required
            disabled={!writable}
          />
        </Box>
        <Box flex={1} marginLeft={1}>
          <FormField<ActivityManagementEditLayoutFormType, TimePickerProps>
            component={DateTimePicker}
            context={ActivityManagementFormContext}
            name="singleEndAt"
            placeholder={t('placeholder.inputEndAt')}
            required
            disabled={!writable}
          />
        </Box>
      </Box>
    </Box>
  )
})

const SingleSettingForm: React.FC = () => {
  const { t } = useT()

  return (
    <Grid container direction="column" spacing={1}>
      <Grid item>
        <Typography variant="h5">{`${t('common.applyCondition')}*`}</Typography>
      </Grid>
      <Grid item>
        <DepositConditionInput />
      </Grid>
      <Grid item>
        <BetConditionInput />
      </Grid>
      <Grid item>
        <Box marginTop={6}>
          <Typography variant="h5">{t('common.applyButton')}</Typography>
        </Box>
      </Grid>
      <Grid item container alignItems="center" spacing={2}>
        <Grid item lg={4} md={12} xs={12}>
          <FrequencyInput />
        </Grid>
        <Grid item lg={8} md={12} xs={12}>
          <DateInput />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default React.memo(SingleSettingForm)
