import { CryptoProtocolType } from '@golden/gdk-admin'
import TKeyType from '../../i18ns/admin/TKeyType'

export const protocolTypeName: { [key in CryptoProtocolType]: TKeyType } = {
  [CryptoProtocolType.ERC20]: 'protocol.erc20',
  [CryptoProtocolType.TRC20]: 'protocol.trc20',
  [CryptoProtocolType.TOPAY]: 'protocol.topay',
  [CryptoProtocolType.EBPAY]: 'protocol.ebpay',
  [CryptoProtocolType.CGPAY]: 'protocol.cgpay',
  [CryptoProtocolType.CGPAY_USDT]: 'protocol.cgpayUsdt',
  [CryptoProtocolType.KOIPAY]: 'protocol.koipay',
  [CryptoProtocolType.GOUBAO]: 'protocol.goubao',
  [CryptoProtocolType.PAY988]: 'protocol.pay988',
  [CryptoProtocolType.PAY808]: 'protocol.pay808'
}
