import React, { useMemo, useContext, useCallback } from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import useT from '../../../i18ns/admin/useT'
import { InitialFormFunc } from '../../../utils/default/ComplexFlowHook'
import { FormContext } from './WithdrawalRCSlipDetailDialog'
import { RCStatusType } from '@golden/gdk-admin'

export interface WithdrawRCSlipFormType {
  status: RCStatusType
  memo: string
}

export const initialForm: InitialFormFunc<WithdrawRCSlipFormType> = (defaultForm) => ({
  status: RCStatusType.IN_PROGRESS,
  memo: '',
  ...defaultForm
})

const memoFormHelperTextProps = { error: true }

const useStyles = makeStyles((theme) => ({
  buttons: {
    marginTop: -20
  },
  button: {
    borderRadius: '50px',
    width: 100
  },
  successful: {
    color: '#72d476',
    border: '1px solid #72d476',
    '&:hover, &.active': {
      border: '1px solid transparent',
      background: '#72d476',
      color: theme.palette.common.white
    }
  },
  irregular: {
    color: theme.palette.secondary.main,
    border: `1px solid ${theme.palette.secondary.main}`,
    '&:hover, &.active': {
      border: '1px solid transparent',
      background: theme.palette.secondary.main,
      color: theme.palette.common.white
    }
  },
  canceled: {
    color: theme.palette.error.main,
    border: `1px solid ${theme.palette.error.main}`,
    '&:hover, &.active': {
      border: '1px solid transparent',
      background: theme.palette.error.main,
      color: theme.palette.common.white
    }
  }
}))

const MemoInput: React.FC<{ disabled: boolean }> = React.memo((props) => {
  const { disabled } = props
  const { value, handleChange, handleOther, error } = useContext(FormContext)
  const { t } = useT()
  const commonClasses = useCommonStyles()
  const memoInputProps = useMemo(() => ({
    classes: { input: commonClasses.memoField },
    inputProps: {
      maxLength: 500,
      placeholder: `RC ${t('common.memo')}`
    }
  }), [commonClasses.memoField, t])
  const helperText = useMemo(() => {
    return [RCStatusType.IRREGULAR, RCStatusType.CANCELED].includes(value.status) && !disabled ? t('placeholder.editBeforeSubmit', { item: t('common.memo') }) : ' '
  }, [t, value.status, disabled])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onChange = useCallback(handleChange('memo'), [])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onFocus = useCallback(handleOther('focus', 'memo'), [])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onBlur = useCallback(handleOther('blur', 'memo'), [])

  return (
    <TextField
      multiline
      fullWidth
      type="text"
      margin="normal"
      variant={ disabled ? 'filled' : 'outlined'}
      value={value.memo}
      disabled={disabled}
      onChange={onChange}
      onFocus={onFocus}
      onBlur={onBlur}
      error={error.memo !== null}
      InputProps={memoInputProps}
      FormHelperTextProps={memoFormHelperTextProps}
      helperText={helperText}
    />
  )
})

const StatusButtons: React.FC<{ hideIrregularButton?: boolean }> = React.memo((props) => {
  const { hideIrregularButton } = props
  const { t } = useT()
  const classes = useStyles()
  const { value, handleChange } = useContext(FormContext)
  const buttons = useMemo(() => {
    return [
      { name: t('rcStatus.successful'), value: RCStatusType.SUCCESSFUL },
      ...hideIrregularButton ? [] : [{ name: t('rcStatus.irregular'), value: RCStatusType.IRREGULAR }],
      { name: t('rcStatus.cancel'), value: RCStatusType.CANCELED }
    ]
  }, [t, hideIrregularButton])

  return (
    <Grid container spacing={1} className={classes.buttons}>
      {
        buttons.map(item =>
          (
            <Grid item key={item.value}>
              <Button
                className={clsx(classes.button, classes[item.value as 'successful' | 'irregular' | 'canceled'], value.status === item.value ? 'active' : null)}
                onClick={() => handleChange('status')(item.value)}
              >
                {item.name}
              </Button>
            </Grid>
          )
        )
      }
    </Grid>
  )
})

const EditWithdrawalRCSlipForm: React.FC<{ writable: boolean, hideIrregularButton?: boolean }> = (props) => {
  const { writable, hideIrregularButton } = props
  return (
    <Box display='flex' alignItems='center'>
      <Box flex={1}>
        <MemoInput disabled={!writable} />
      </Box>
      {
        writable && (
          <Box marginLeft={1}>
            <StatusButtons hideIrregularButton={hideIrregularButton} />
          </Box>
        )
      }
    </Box>
  )
}

export default EditWithdrawalRCSlipForm
