import { FinanceRoleType } from '@golden/gdk-admin'
import TKeyType from '../../i18ns/admin/TKeyType'

const financeRoleTypeName: { [key in FinanceRoleType]: TKeyType } = {
  [FinanceRoleType.NOT_DEPOSIT_ONLINE]: 'financeRoleType.notDepositOnline',
  [FinanceRoleType.NOT_DEPOSIT_ERC20]: 'financeRoleType.notDepositErc20',
  [FinanceRoleType.NOT_DEPOSIT_TRC20]: 'financeRoleType.notDepositTrc20',
  [FinanceRoleType.NOT_DEPOSIT_SCAN_WECHAT]: 'financeRoleType.notDepositScanWechat',
  [FinanceRoleType.NOT_DEPOSIT_SCAN_ALIPAY]: 'financeRoleType.notDepositScanAlipay',
  [FinanceRoleType.NOT_DEPOSIT_GOUBOU]: 'financeRoleType.notDepositGoubou',
  [FinanceRoleType.NOT_DEPOSIT_TOPAY]: 'financeRoleType.notDepositTopay',
  [FinanceRoleType.NOT_DEPOSIT_EBPAY]: 'financeRoleType.notDepositEbpay',
  [FinanceRoleType.NOT_DEPOSIT_ONLINE_MANUAL]: 'financeRoleType.notDepositOnlineManual',
  [FinanceRoleType.NOT_DEPOSIT_UNION_PAY_QUICK]: 'financeRoleType.notDepositUnionPayQuick',
  [FinanceRoleType.NOT_DEPOSIT_CGPAY]: 'financeRoleType.notDepositCGPay',
  [FinanceRoleType.NOT_DEPOSIT_KOIPAY]: 'financeRoleType.notDepositKOIPay',
  [FinanceRoleType.NOT_DEPOSIT_ECNY]: 'financeRoleType.notDepositECNY',
  [FinanceRoleType.NOT_DEPOSIT_PAY808]: 'financeRoleType.notDepositPay808',
  [FinanceRoleType.NOT_DEPOSIT_PAY988]: 'financeRoleType.notDepositPay988',
  [FinanceRoleType.NOT_WITHDRAW_DEBIT_CARD]: 'financeRoleType.notWithdrawDebitCard',
  [FinanceRoleType.NOT_WITHDRAW_ERC20]: 'financeRoleType.notWithdrawErc20',
  [FinanceRoleType.NOT_WITHDRAW_TRC20]: 'financeRoleType.notWithdrawTrc20',
  [FinanceRoleType.NOT_WITHDRAW_GOUBOU]: 'financeRoleType.notWithdrawGoubou',
  [FinanceRoleType.NOT_WITHDRAW_TOPAY]: 'financeRoleType.notWithdrawTopay',
  [FinanceRoleType.NOT_WITHDRAW_EBPAY]: 'financeRoleType.notWithdrawEbpay',
  [FinanceRoleType.NOT_WITHDRAW_CGPAY]: 'financeRoleType.notWithdrawCGPay',
  [FinanceRoleType.NOT_WITHDRAW_CGPAY_USDT]: 'financeRoleType.notWithdrawCGPayUsdt',
  [FinanceRoleType.NOT_WITHDRAW_KOIPAY]: 'financeRoleType.notWithdrawKOIPay',
  [FinanceRoleType.NOT_WITHDRAW_PAY808]: 'financeRoleType.notWithdrawPay808',
  [FinanceRoleType.NOT_WITHDRAW_PAY988]: 'financeRoleType.notWithdrawPay988',
  [FinanceRoleType.NOT_WITHDRAW_AGENT_TRANSFER_WITHDRAW]: 'financeRoleType.notWithdrawAgentTransferWithdraw'
}

export default financeRoleTypeName
