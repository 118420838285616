import React, { useMemo, useContext, useState, useCallback } from 'react'
import { ActivityBetConditionType, ActivityDepositConditionType, ActivityGameDurationType, ActivityLayoutGameSetting, GameType } from '@golden/gdk-admin'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import AddIcon from '@material-ui/icons/Add'
import MuiTextField from '@material-ui/core/TextField'
import DateTimePicker from '../../default/form/DateTimePicker'
import { ValueGetter } from '../../../utils/default/FormHook'
import { InitialFormFunc } from '../../../utils/default/ComplexFlowHook'
import useT from '../../../i18ns/admin/useT'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import CoreTable from '../../../components/default/present/CoreTable'
import { createTableData } from '../../../utils/default/TableHelper'
import { ActivityManagementFormContext } from './ActivityManagementEditLayoutForm'
import { BetConditionInput, DepositConditionInput } from './ActivityManagementEditLayoutSettingForm'
import { getValueFromChangeEvent, getValueFromCheckboxEvent, getValueFromValue } from '../../../utils/default/FormHelper'
import { isAfter, isBefore, isValid } from 'date-fns'
import { range } from '@golden/utils'
import { useChecker } from '../../../utils/admin/AdminRouteHook'

export interface GameFormType {
  id: number | null
  league: string
  game: string
  game_start_at: Date | null
  bet_start_at: Date | null
  bet_end_at: Date | null
  start_at: Date | null
  end_at: Date | null
}
export interface GameSettingFormType {
  gameHasDepositCondition: boolean
  gameDepositType: ActivityDepositConditionType
  gameDepositDuration: ActivityGameDurationType | '-'
  gameDepositThreshold: string
  gameHasBetCondition: boolean
  gameBetType: ActivityBetConditionType
  gameBetThreshold: string
  gameBetGameType: 'gameId' | 'sportGames' | null
  gameBetGameIds: GameType[]
  gameBetSportGames: GameSportsFormType[]
  gameGames: GameFormType[]
}

export interface GameSportsFormType {
  game_id: GameType | null
  tournament_id: number | null
  match_id?: number | null
}

export const initialForm: InitialFormFunc<GameSettingFormType> = (form) => ({
  gameHasDepositCondition: false,
  gameDepositType: ActivityDepositConditionType.SINGLE,
  gameDepositDuration: '-',
  gameDepositThreshold: '',
  gameHasBetCondition: false,
  gameBetType: ActivityBetConditionType.SINGLE,
  gameBetThreshold: '',
  gameBetGameType: null,
  gameBetGameIds: [],
  gameBetSportGames: [{ game_id: null, tournament_id: null, match_id: null }],
  gameGames: [{ id: null, league: '', game: '', game_start_at: null, start_at: null, end_at: null, bet_start_at: null, bet_end_at: null }],
  ...form
})

export const getValueFromEvent: ValueGetter<GameSettingFormType> = {
  gameHasDepositCondition: getValueFromCheckboxEvent,
  gameDepositType: getValueFromChangeEvent,
  gameDepositDuration: getValueFromChangeEvent,
  gameDepositThreshold: getValueFromChangeEvent,
  gameHasBetCondition: getValueFromCheckboxEvent,
  gameBetType: getValueFromChangeEvent,
  gameBetThreshold: getValueFromChangeEvent,
  gameBetGameType: getValueFromChangeEvent,
  gameBetGameIds: getValueFromValue,
  gameBetSportGames: getValueFromChangeEvent,
  gameGames: getValueFromValue
}

const TextField = React.memo(MuiTextField)

interface GameRowType {
  index: number
  id: number | null
  delete: React.ReactElement
  league: React.ReactElement
  game: React.ReactElement
  gameTime: React.ReactElement
  frequency: string
  betTime: React.ReactElement
  applyTime: React.ReactElement
}

const GameDeleteButton: React.FC<{
  index: number
  error: ErrorType[]
  setError: (value: ErrorType[]) => void
}> = React.memo((props) => {
  const { index, error, setError } = props
  const { value: form, dispatch } = useContext(ActivityManagementFormContext)
  return (
    <IconButton
      size="small"
      onClick={() => {
        dispatch({
          type: 'change',
          label: 'gameGames',
          value: form.gameGames.filter((_, i) => i !== index)
        })
        setError(error.filter((_, i) => i !== index))
      }}
    >
      <CloseIcon fontSize="small" />
    </IconButton>
  )
})

interface ErrorType {
  league: string
  game: string
  game_start_at: string
  bet_start_at: string
  bet_end_at: string
  start_at: string
  end_at: string
}

const GameTextField: React.FC<{
  value: string
  name: keyof ActivityLayoutGameSetting
  index: number
  placeholder: string
  error: ErrorType[]
  setError: (value: ErrorType[]) => void
  disabled: boolean
}> = React.memo((props) => {
  const { value, name, index, placeholder, error, setError, disabled } = props
  const { t } = useT()
  const { value: form, dispatch } = useContext(ActivityManagementFormContext)
  return (
    <TextField
      value={value}
      fullWidth
      placeholder={placeholder}
      onChange={(event) => {
        const value = event.target.value
        dispatch({
          type: 'change',
          label: 'gameGames',
          value: form.gameGames.map((game, i) => index === i ? ({ ...game, [name]: value }) : game)
        })
        setError(error.map((e, i) => i === index
          ? { ...e, [name]: !value ? t('error.mustNotEmpty') : '' }
          : e
        ))
      }}
      error={!!error[index][name as keyof ErrorType]}
      helperText={error[index][name as keyof ErrorType]}
      inputProps={{ maxLength: 50 }}
      disabled={disabled}
    />
  )
})

const GameDateTimePicker: React.FC<{
  value: Date | null
  name: keyof ActivityLayoutGameSetting
  index: number
  placeholder?: string
  error?: boolean
  helperText?: string
  onChange: (value: Date | null) => void
  disabled?: boolean
}> = React.memo((props) => {
  const { value, name, index, placeholder, error, helperText, onChange, disabled } = props
  const { value: form, dispatch } = useContext(ActivityManagementFormContext)
  return (
    <DateTimePicker
      fullWidth
      value={value}
      placeholder={placeholder ?? ''}
      onChange={(value) => {
        onChange(value)
        dispatch({
          type: 'change',
          label: 'gameGames',
          value: form.gameGames.map((game, i) => index === i ? ({ ...game, [name]: value }) : game)
        })
      }}
      error={error}
      helperText={helperText}
      disabled={disabled}
    />
  )
})

const GameDateRangeInput: React.FC<{
  index: number
  startAtName: 'start_at' | 'bet_start_at'
  endAtName: 'end_at' | 'bet_end_at'
  error: ErrorType[]
  setError: React.Dispatch<React.SetStateAction<ErrorType[]>>
  disabled?: boolean
}> = React.memo((props) => {
  const { index, startAtName, endAtName, error, setError, disabled } = props
  const { t } = useT()
  const { value: form } = useContext(ActivityManagementFormContext)
  const item = useMemo(() => form.gameGames[index], [index, form.gameGames])

  const validateStart = useCallback((start: Date | null, end: Date | null) => {
    if (!start) return t('error.mustNotEmpty')
    else if (!isValid(start)) return t('error.dateError')
    else if (end && isAfter(start, end)) return t('error.startMustBeforeEnd')
    else return ''
  }, [t])
  const validateEnd = useCallback((start: Date | null, end: Date | null) => {
    if (!end) return t('error.mustNotEmpty')
    else if (!isValid(end)) return t('error.dateError')
    else if (start && isBefore(end, start)) return t('error.endMustAfterStart')
    else return ''
  }, [t])

  const handleStartChange = useCallback((value: Date | null) => {
    const startAt = validateStart(value, item[endAtName])
    const endAt = validateEnd(value, item[endAtName])
    setError(error.map((e, i) => i === index ? { ...e, [startAtName]: startAt, [endAtName]: endAt } : e))
  }, [endAtName, error, index, item, setError, startAtName, validateEnd, validateStart])

  const handleEndChange = useCallback((value: Date | null) => {
    const startAt = validateStart(item[startAtName], value)
    const endAt = validateEnd(item[startAtName], value)
    setError(error.map((e, i) => i === index ? { ...e, [startAtName]: startAt, [endAtName]: endAt } : e))
  }, [endAtName, error, index, item, setError, startAtName, validateEnd, validateStart])

  return (<Grid container direction="column" spacing={1}>
    <Grid item container>
      <GameDateTimePicker
        name={startAtName}
        index={index}
        value={item[startAtName]}
        placeholder={t('placeholder.inputBeginAt')}
        onChange={handleStartChange}
        error={!!error[index][startAtName]}
        helperText={error[index][startAtName]}
        disabled={disabled}
      />
    </Grid>
    <Grid item container>
      <GameDateTimePicker
        name={endAtName}
        index={index}
        value={item[endAtName]}
        placeholder={t('placeholder.inputEndAt')}
        onChange={handleEndChange}
        error={!!error[index][endAtName]}
        helperText={error[index][endAtName]}
        disabled={disabled}
      />
    </Grid>
  </Grid>)
})

const GameAddButton: React.FC<{
  error: ErrorType[]
  setError: (value: ErrorType[]) => void
}> = React.memo((props) => {
  const { error, setError } = props
  const { value: form, dispatch } = useContext(ActivityManagementFormContext)
  return (
    <IconButton
      onClick={() => {
        const defaultGame = { league: '', game: '', game_start_at: null, bet_start_at: null, bet_end_at: null, start_at: null, end_at: null }
        dispatch({
          type: 'change',
          label: 'gameGames',
          value: [...form.gameGames, defaultGame]
        })
        setError(error.concat({ league: '', game: '', game_start_at: '', bet_start_at: '', bet_end_at: '', start_at: '', end_at: '' }))
      }}
    >
      <AddIcon fontSize="small" />
    </IconButton>
  )
})

const GameTable: React.FC = React.memo(() => {
  const { t } = useT()
  const { value: form } = useContext(ActivityManagementFormContext)
  const writable = useChecker()
  const commonClasses = useCommonStyles()
  const tableClasses = useMemo(() => ({
    head: commonClasses.pinkTableHead,
    row: commonClasses.tableRow,
    cellHead: commonClasses.tableCellHead,
    cellBody: commonClasses.pre
  }), [commonClasses])

  const defaultErrorLength = useMemo(() => form.gameGames.length, [form.gameGames.length])
  const [error, setError] = useState<ErrorType[]>(range(0, defaultErrorLength).map(() => ({ league: '', game: '', game_start_at: '', bet_start_at: '', bet_end_at: '', start_at: '', end_at: '' })))

  const rows: GameRowType[] = useMemo(() => {
    return form.gameGames.map((item, index) => {
      return {
        id: index,
        delete: (
          <GameDeleteButton
            index={index}
            error={error}
            setError={setError}
          />
        ),
        league: (
          <GameTextField
            name="league"
            index={index}
            value={item.league}
            placeholder={t('placeholder.pleaseInput', { item: t('common.gameLeague') })}
            error={error}
            setError={setError}
            disabled={!writable}
          />
        ),
        game: (
          <GameTextField
            name="game"
            index={index}
            value={item.game}
            placeholder={t('placeholder.pleaseInput', { item: t('common.designatedGame') })}
            error={error}
            setError={setError}
            disabled={!writable}
          />
        ),
        gameTime: (
          <GameDateTimePicker
            name="game_start_at"
            index={index}
            value={item.game_start_at}
            placeholder={t('placeholder.pleaseChoose', { item: t('common.gameTime') })}
            onChange={(value) => {
              setError(error.map((e, i) => i === index
                ? {
                    ...e,
                    game_start_at: !value
                      ? t('error.mustNotEmpty')
                      : !isValid(value)
                          ? t('error.dateError')
                          : ''
                  }
                : e))
            }}
            error={!!error[index].game_start_at}
            helperText={error[index].game_start_at}
            disabled={!writable}
          />
        ),
        frequency: t('common.oneTime'),
        betTime: (<GameDateRangeInput
          startAtName="bet_start_at"
          endAtName="bet_end_at"
          index={index}
          error={error}
          setError={setError}
          disabled={!writable || !form.gameHasBetCondition}
        />),
        applyTime: (<GameDateRangeInput
          startAtName="start_at"
          endAtName="end_at"
          index={index}
          error={error}
          setError={setError}
          disabled={!writable}
        />)
      } as GameRowType
    })
  }, [error, form.gameGames, form.gameHasBetCondition, t, writable])
  const data = useMemo(() => {
    const order: Array<keyof GameRowType> = [
      'league',
      'game',
      'gameTime',
      'frequency',
      'betTime',
      'applyTime'
    ]
    return createTableData<GameRowType>(
      {
        index: {
          label: '',
          value: 'index'
        },
        id: {
          label: '',
          value: 'id'
        },
        delete: {
          label: '',
          value: 'delete',
          align: 'center',
          width: 10
        },
        league: {
          label: `${t('common.gameLeague')}*`,
          value: 'league',
          align: 'center',
          width: 100
        },
        game: {
          label: `${t('common.designatedGame')}*`,
          value: 'game',
          align: 'center',
          width: 100
        },
        gameTime: {
          label: `${t('common.gameTime')}*`,
          value: 'gameTime',
          align: 'center',
          width: 80
        },
        frequency: {
          label: t('common.applyFrequency'),
          value: 'frequency',
          align: 'center',
          width: 50
        },
        betTime: {
          label: `${t('common.betTime')}${form.gameHasBetCondition ? '*' : ''}`,
          value: 'betTime',
          align: 'center',
          width: 80
        },
        applyTime: {
          label: `${t('common.applyTime')}*`,
          value: 'applyTime',
          align: 'center',
          width: 80
        }
      },
      form.gameGames.length > 1 && writable ? ['delete', ...order] : order,
      rows,
      'index'
    )
  }, [t, form.gameHasBetCondition, form.gameGames.length, writable, rows])

  return (<>
    <CoreTable
      classes={tableClasses}
      data={data}
      total={rows.length}
    />
    { writable && (<GameAddButton
      error={error}
      setError={setError}
    />) }
  </>)
})

const GameSettingForm = () => {
  const { t } = useT()

  return (
    <Grid container direction="column" spacing={1}>
      <Grid item>
        <Typography variant="h5">{`${t('common.applyCondition')}*`}</Typography>
      </Grid>
      <Grid item>
        <DepositConditionInput />
      </Grid>
      <Grid item>
        <BetConditionInput />
      </Grid>
      <Grid item>
        <Box marginTop={6}>
          <Typography variant="h5">{t('common.applyButton')}</Typography>
        </Box>
      </Grid>
      <Grid item>
        <Typography color="error">{t('common.applyButtonGameTip')}</Typography>
      </Grid>
      <Grid item>
        <GameTable />
      </Grid>
    </Grid>
  )
}

export default React.memo(GameSettingForm)
