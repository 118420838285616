import { ActivityStatusType, RecommendFriendStateType, ActivityNewMemberBonusStateType, ActivityDailySignInStateType, ActivityDepositBonusStatusType, ActivityUefaBuildingUpStateType, ActivityUefaStarsStatusType, ActivityWeeklyBonusDrawStatusType } from '@golden/gdk-campaign'

export const activityStatusColor: { [key in ActivityStatusType]: string } = {
  [ActivityStatusType.SUCCESS]: '#72d476',
  [ActivityStatusType.RECEIVED]: '#b86fff',
  [ActivityStatusType.SENT]: '#ff5f5f',
  [ActivityStatusType.EXPIRED]: '#5e7188',
  [ActivityStatusType.CANCELED]: '#888888'
}

export const recommendFriendStatusColor: { [key in RecommendFriendStateType]: string } = {
  [RecommendFriendStateType.IN_PROGRESS]: '#72d476',
  [RecommendFriendStateType.RECEIVED]: '#b86fff',
  [RecommendFriendStateType.EXPIRED]: '#5e7188',
  [RecommendFriendStateType.CANCELED]: '#888888'
}

export const newMemberBonusStatusColor: { [key in ActivityNewMemberBonusStateType]: string } = {
  [ActivityNewMemberBonusStateType.JOINED]: '#72d476',
  [ActivityNewMemberBonusStateType.BONUS_RECEIVED]: '#b86fff',
  [ActivityNewMemberBonusStateType.BONUS_EXPIRED]: '#5e7188',
  [ActivityNewMemberBonusStateType.CANCELED]: '#888888'
}

export const dailySignInStateColor: { [key in ActivityDailySignInStateType]: string } = {
  [ActivityDailySignInStateType.APPLIED]: '#72d476',
  [ActivityDailySignInStateType.RECEIVED]: '#b86fff',
  [ActivityDailySignInStateType.EXPIRED]: '#5e7188',
  [ActivityDailySignInStateType.CANCELED]: '#888888'
}

export const depositBonusStatusColor: { [key in ActivityDepositBonusStatusType]: string } = {
  [ActivityDepositBonusStatusType.RECEIVED]: '#b86fff'
}

export const uefaBuildingUpStateColor: { [key in ActivityUefaBuildingUpStateType]: string } = {
  [ActivityUefaBuildingUpStateType.APPLIED]: '#72d476',
  [ActivityUefaBuildingUpStateType.RECEIVED]: '#b86fff',
  [ActivityUefaBuildingUpStateType.EXPIRED]: '#5e7188',
  [ActivityUefaBuildingUpStateType.CANCELED]: '#888888'
}

export const uefaStarsStatusColor: { [key in ActivityUefaStarsStatusType]: string } = {
  [ActivityUefaStarsStatusType.NO_USE]: '#888888',
  [ActivityUefaStarsStatusType.WIN]: '#72d476',
  [ActivityUefaStarsStatusType.LOST]: '#ff5f5f',
  [ActivityUefaStarsStatusType.EXPIRED]: '#5e7188',
  [ActivityUefaStarsStatusType.RECEIVED]: '#b86fff',
  [ActivityUefaStarsStatusType.NOT_RECEIVED]: '#ff5f5f'
}

export const weeklyBonusDrawStatusColor: { [key in ActivityWeeklyBonusDrawStatusType]: string } = {
  [ActivityWeeklyBonusDrawStatusType.EXPIRED]: '#5e7188',
  [ActivityWeeklyBonusDrawStatusType.RECEIVED]: '#b86fff',
  [ActivityWeeklyBonusDrawStatusType.NOT_RECEIVED]: '#ff5f5f'
}

export default activityStatusColor
