import { BindAccountType } from '@golden/gdk-campaign'
import TKeyType from '../../i18ns/admin/TKeyType'

export const bindAccount: { [key in BindAccountType]: TKeyType } = {
  [BindAccountType.DEBIT_CARD]: 'common.cardNo',
  [BindAccountType.CRYPTO_ERC]: 'protocol.erc20',
  [BindAccountType.CRYPTO_TRC]: 'protocol.trc20',
  [BindAccountType.TOPAY]: 'protocol.topay',
  [BindAccountType.EBPAY]: 'protocol.ebpay',
  [BindAccountType.GOUBAO]: 'protocol.goubao',
  [BindAccountType.CGPAY]: 'protocol.cgp',
  [BindAccountType.KOIPAY]: 'protocol.koipay',
  [BindAccountType.PAY808]: 'protocol.pay808',
  [BindAccountType.PAY988]: 'protocol.pay988'
}
