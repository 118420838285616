import { ActivityDurationType, ActivityGameDurationType } from '@golden/gdk-admin'
import TKeyType from '../../i18ns/admin/TKeyType'

export const activityDurationName: { [key in ActivityDurationType]: TKeyType } = {
  [ActivityDurationType.TODAY]: 'common.thisDay',
  [ActivityDurationType.YESTERDAY]: 'common.yesterday',
  [ActivityDurationType.THIS_WEEK]: 'common.thisWeek2',
  [ActivityDurationType.LAST_WEEK]: 'common.lastWeek',
  [ActivityDurationType.THIS_MONTH]: 'common.thisMonth2',
  [ActivityDurationType.LAST_MONTH]: 'common.lastMonth'
}

export const activityGameDurationName: { [key in ActivityGameDurationType]: TKeyType } = {
  [ActivityGameDurationType.GAME_DAY]: 'common.dayBeforeGameInHours',
  [ActivityGameDurationType.GAME_WEEK]: 'common.weekBeforeGameStart'
}

export default activityDurationName
