import React from 'react'
import clsx from 'clsx'
import { GAME_ORDER } from '@golden/game-china'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import useT from '../../../i18ns/admin/useT'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import { formatMoney } from '../../../utils/default/TableHelper'
import CustomThemeType from '../../../themes/default/CustomThemeType'
import { GameType, WithdrawPointTarget } from '@golden/gdk-admin'

export interface PropTypes {
  tablePayloads: Array<{ name: string, width?: number, isNested?: boolean, value: string | React.ReactElement }>
}

interface NestedCellPropTypes {
  info: Array<string | React.ReactElement | undefined>
  justifyContent?: 'center' | 'start' | 'end'
}

interface PointCellPropTypes {
  point: string
  isColored?: boolean
  showPlusOnPositive?: boolean
}

const useStyles = makeStyles((theme: CustomThemeType) => ({
  fourBorder: {
    border: '1px solid rgba(233, 233, 233, 1)'
  },
  bottomBorder: {
    borderBottom: '1px solid rgba(233, 233, 233, 1)'
  },
  fourPadding: {
    padding: theme.spacing(1)
  },
  noPadding: {
    padding: 0
  },
  head: {
    borderTop: '1px solid rgba(233, 233, 233, 1)'
  },
  cellHead: {
    whiteSpace: 'nowrap',
    color: theme.palette.text.primary
  },
  cellBody: {
    color: theme.palette.text.secondary
  },
  fixedHeightRow: {
    height: '60px'
  }
}))

const getColor = (n: number) => {
  if (n === 0) return '#808080'
  if (n > 0) return '#55B559'
  return '#f44336'
}

export const sortBuckets: <T extends { target: WithdrawPointTarget, target_id?: GameType }>(buckets: T[]) => T[] = (buckets) => {
  const ORDER = ([WithdrawPointTarget.GENERAL, WithdrawPointTarget.ACTIVITY] as Array<WithdrawPointTarget | GameType>).concat(GAME_ORDER)
  return buckets.slice().sort((a, b) => {
    const aIndex = ORDER.findIndex((el) => a.target === WithdrawPointTarget.GAME ? el === a.target_id : el === a.target)
    const bIndex = ORDER.findIndex((el) => b.target === WithdrawPointTarget.GAME ? el === b.target_id : el === b.target)
    return aIndex - bIndex
  })
}

export const PointCell: React.FC<PointCellPropTypes> = (props) => {
  const { point, isColored, showPlusOnPositive } = props
  return (
    <Box style={{ color: getColor((isColored ? Number(point) : 0)) }}>
      {(showPlusOnPositive ? (Number(point) > 0 ? '+' : '') : '')}{formatMoney(point)}
    </Box>
  )
}

export const NestedCell: React.FC<NestedCellPropTypes> = (props) => {
  const { info, justifyContent = 'center' } = props
  const classes = useStyles()
  return (
    <React.Fragment>
      {info.map((el, index) => (
        <Box
          className={clsx(classes.fourPadding, (index < (info.length - 1) ? classes.bottomBorder : ''), classes.fixedHeightRow)}
          alignItems="center"
          justifyContent={justifyContent}
          display="flex"
          key={`'nested-cell'-${index}`}
        >
          {el}
        </Box>
      ))}
    </React.Fragment>
  )
}

const EffectiveCashSummaryTable: React.FC<PropTypes> = (props) => {
  const {
    tablePayloads
  } = props
  const classes = useStyles()
  const commonClasses = useCommonStyles()
  const { t } = useT()

  if (tablePayloads.length === 0) {
    return (
      <Box display="flex" justifyContent="center" padding={1} overflow="auto">
        <Typography variant="h3">
          {t('common.noData')}
        </Typography>
      </Box>
    )
  }

  return (
    <Table size='medium' className={classes.fourBorder}>
      <TableHead className={clsx(classes.head, commonClasses.pinkTableHead)}>
        <TableRow className={commonClasses.tableRow}>
          {tablePayloads.map((el, index) => {
            return (
              <TableCell
                classes={{ head: clsx(classes.cellHead, commonClasses.tableCellHead) }}
                className={clsx(classes.fourPadding, classes.fourBorder)}
                align='center'
                key={`the${index}-rdColumnName-${el.name}`}
                style={{ minWidth: el.width ?? 'auto' }}
              >
                {el.name}
              </TableCell>
            )
          })}
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow
          className={commonClasses.tableRow}
        >
          {tablePayloads.map(el => el.value).map((el, index) => {
            return (
              <TableCell
                classes={{ head: clsx(classes.cellBody, commonClasses.pre) }}
                className={clsx((tablePayloads[index].isNested ? classes.noPadding : classes.fourPadding), classes.fourBorder)}
                align='center'
                key={`the${index}-rdColumnContent`}
                style={{ minWidth: tablePayloads[index].width ?? 'auto' }}
              >
                {el}
              </TableCell>
            )
          })}
        </TableRow>
      </TableBody>
    </Table>
  )
}

export default React.memo(EffectiveCashSummaryTable)
